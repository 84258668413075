<template>
  <!-- 举报内容审核页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 申请人姓名 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
                  </a-form-item>
                </a-col>
                <!-- 审核状态 -->
                <a-col :md="6" :sm="24">
                  <a-form-item label="审核状态">
                    <a-select v-model="queryParam.result" allow-clear placeholder="请选择">
                      <a-select-option v-for="(item, index) in resultList" :key="index" :value="item.status">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-button type="primary" @click="searchTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                </a-col>
                <a-col :md="24" :sm="24" style="margin-bottom: 20px">
                  <a-button
                    @click="
                      selectRowId.length > 0
                        ? $refs.batchForm.batch({ ids: selectRowId, result: '2' })
                        : showToastTips()
                    "
                    style="border-radius: 4px"
                    type="primary"
                    >批量通过</a-button
                  >
                  <a-button
                    @click="
                      selectRowId.length > 0
                        ? $refs.batchForm.batch({ ids: selectRowId, result: '3' })
                        : showToastTips()
                    "
                    style="margin-left: 10px; border-radius: 4px"
                    >批量拒绝</a-button
                  >
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :row-selection="rowSelection"
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
            <span slot="avatar" slot-scope="text">
              <img :src="text" style="width: 45px; height: 45px; border-radius: 50%" />
            </span>
            <!-- 审核状态插槽 -->
            <span slot="checkResult" slot-scope="text">
              <a-tag style="line-height: 20px" :color="text == 1 ? 'orange' : text == 2 ? 'green' : 'red'">
                {{ text == 1 ? '待审核' : text == 2 ? '已通过' : text == 3 ? '已拒绝' : '' }}
              </a-tag>
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <!-- <a @click="$refs.detailForm.detail(record)">详情</a> -->
              <!-- <a-divider type="vertical" />
							<a-popconfirm placement="topRight" title="确认删除？" @confirm="() => sysItemDelete(record)">
								<a>删除</a>
							</a-popconfirm> -->
              <span v-show="record.result == 1">
                <!-- <a-divider type="vertical" /> -->
                <a @click="$refs.oneForm.one({ ids: [record.id], result: '2' })">通过</a>
                <a-divider type="vertical" />
                <a @click="$refs.oneForm.one({ ids: [record.id], result: '3' })">拒绝</a>
              </span>
            </span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            >
            </a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
    <!-- 引入子组件 -->
    <batch-form @ok="getList" ref="batchForm"></batch-form>
    <one-form @ok="getList" ref="oneForm"></one-form>
  </div>
</template>

<script>
import {
  findApplyLiveStreamingPageList,
  getApplyLiveStreamingDetail,
  examineApplyLiveStreaming,
} from '@/api/modular/mallLiving/liveAudit'

import batchForm from './batchForm.vue'
import oneForm from './oneForm.vue'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '60px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '头像',
    align: 'center',
    width: '80px',
    dataIndex: 'avatar',
    scopedSlots: {
      customRender: 'avatar',
    },
  },
  {
    title: '申请商家',
    align: 'center',
    width: '120px',
    dataIndex: 'nickname',
  },
  {
    title: '申请人手机号',
    dataIndex: 'mobile',
    width: '120px',
    align: 'center',
  },
  {
    title: 'ID号',
    align: 'center',
    width: '120px',
    dataIndex: 'idNo',
  },
  {
    title: '申请原因',
    dataIndex: 'applyReason',
    width: '340px',
    align: 'center',
  },
  {
    title: '审核状态',
    dataIndex: 'result',
    width: '80px',
    align: 'center',
    scopedSlots: {
      customRender: 'checkResult',
    },
  },
  {
    title: '审核备注信息',
    dataIndex: 'remark',
    width: '320px',
    align: 'center',
  },
  {
    title: '申请时间',
    dataIndex: 'createTime',
    width: '150px',
    align: 'center',
  },
  {
    title: '操作',
    align: 'left',
    width: '100px',
    dataIndex: 'action',
    fixed: 'right',
    key: 'action',
    scopedSlots: {
      customRender: 'action',
    },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      selectedRowKeys: [], //表格行的选中项数组
      selectRowId: [], //选中行的id数组
      queryParam: {
        keyword: '',
        result: undefined,
      },
      resultList: [
        {
          status: 1,
          name: '待审核',
        },
        {
          status: 2,
          name: '已通过',
        },
        {
          status: 3,
          name: '已拒绝',
        },
      ],
    }
  },
  components: {
    batchForm,
    oneForm,
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    showToastTips() {
      this.$message.error('请选择要进行批量操作的数据')
      return
    },
    sysItemDelete(record) {
      reportDelete({
        id: record.id,
        delFlag: 2,
      }).then((res) => {
        if (res.success) {
          this.getList()
          this.$message.success('该条投诉已删除')
        } else {
          this.$message.error('删除失败')
        }
      })
    },
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    searchTableData() {
      //校验手机号长度
      if (this.queryParam.mobile && this.queryParam.mobile.toString().length > 11) {
        this.$message.error('手机号码格式不正确，请重新输入')
        return
      }
      this.initTableData()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    getList() {
      this.loading = true
      let query = {
        queryParam: this.queryParam,
        page: this.page,
      }
      findApplyLiveStreamingPageList(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectRowData) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectRowId = selectRowData.map((item) => {
            return item.id
          })
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.result != 1, //如果已经被审核，禁用选择框
          },
        }),
      }
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 20px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 20px;
  height: 20px;
}
</style>
